import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, Input, Select, message, Popconfirm } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { LoadingView, Top, UploadFile, Editor } from '../../components'
import { FIND_UNIQUE_NEWS, UPDATE_ONE_NEWS } from '../../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
    .upload-container {
        display: flex;
        border: 1px dashed #d9d9d9;
        position: relative;
        overflow: hidden;
        align-items: center;
        box-sizing: border-box;
        width: fit-content;

        .ant-upload {
            width: 100%;
        }

        .upload {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            height: fit-content;
            font-size: 17px;
            height: 100%;
        }

        .mask {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
            color: #fff;
            font-size: 17px;
            padding: 15px;
            box-sizing: border-box;
        }

        :hover {
            .mask {
                display: flex;
            }
        }
    }
`
const Image = styled.img`
    max-width: 100%;
    aspect-ratio: ${({ ratio }) => ratio};
    object-fit: contain;
    object-position: center;
    background-color: #fafafa;
`
const Note = styled.div`
    margin-bottom: 24px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const EditNews = () => {
    const [form] = Form.useForm()
    const [image, setImage] = useState(undefined)
    const [content, setContent] = useState(undefined)
    const navigate = useNavigate()
    const { id } = useParams()

    const { data, loading } = useQuery(FIND_UNIQUE_NEWS, {
        variables: {
            where: { id }
        },
        onCompleted: ({ findUniqueNews: { image, title, content } }) => {
            form.setFieldsValue({
                image,
                title,
                content,
            })
            setImage(image)
            setContent(content)
        }
    })

    const [updateNews, { loading: updateLoading }] = useMutation(UPDATE_ONE_NEWS, {
        onCompleted: () => {
            form.resetFields()
            message.success(`Новость обновлена`)
            navigate(`/news`)
        },
        onError: e => {
            message.error("Что то пошло не так, повторите попытку позже")
        }
    })

    const handleSubmit = ({ image, title, content, tags }) => {
        const data = {
            image: { set: image },
            title: { set: title },
            content: { set: content },
            tags,
        }
        updateNews({
            variables: {
                where: { id },
                data
            }
        })
    }

    const news = useMemo(() => data ? data.findUniqueNews : null, [data])

    return (
        <>
            <Top
                title={'Создание публикации'}
            />
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Form.Item
                    name='image'
                    rules={[rules.required]}
                    label='Изображение'
                >
                    <UploadFile
                        className='upload-container'
                        onChange={name => setImage(name)}
                        itemRender={() => null}
                    >
                        {
                            image ? (
                                <div className='upload'>
                                    <Image src={`/uploads/${image}`} ratio={'3/2'} />
                                    <div className='mask'>
                                        <UploadOutlined style={{ marginRight: 5 }} />
                                        Загрузить другое изображение
                                    </div>
                                </div>
                            ) : (
                                <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
                            )
                        }
                    </UploadFile>
                </Form.Item>
                <Form.Item
                    name={"title"}
                    rules={[rules.required]}
                    label="Заголовок"
                >
                    <Input placeholder='Введите заголовок' />
                </Form.Item>
                <Form.Item
                    name={"content"}
                    rules={[rules.required]}
                    label="Контент"
                >
                    {
                        content && (
                            <Editor
                                defaultValue={content}
                            />
                        )
                    }
                </Form.Item>
                <Button
                    loading={updateLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 15 }}
                >
                    Сохранить
                </Button>
                <Popconfirm
                    title='Удлить новость?'
                    onConfirm={() => {
                        updateNews({
                            variables: {
                                where: { id },
                                data: {
                                    delete: { set: true }
                                }
                            }
                        })
                    }}
                >
                    <Button
                        loading={updateLoading}
                        type="danger"
                        style={{ marginRight: 15 }}
                    >
                        Удалить
                    </Button>
                </Popconfirm>
                {
                    news && (
                        <Button
                            loading={updateLoading}
                            style={{ marginRight: 15 }}
                            onClick={() => {
                                updateNews({
                                    variables: {
                                        where: { id },
                                        data: {
                                            publish: { set: !news.publish }
                                        }
                                    }
                                })
                            }}
                        >
                            {news.publish ? "Снять с публикации" : "Опубликовать"}
                        </Button>
                    )
                }
            </Form>
            <LoadingView loading={loading} />
        </>
    )
}

export default EditNews