import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Menu as AntMenu } from 'antd'

import { getPermission, useUser } from '../utils/hooks'

export const Menu = () => {
    const { pathname } = window.location
    const { user } = useUser()

    return (
        <AntMenu theme="dark" mode="inline" defaultSelectedKeys={[pathname]}>
            <AntMenu.Item key={`/`}>
                <MenuLink to={`/`}>
                    Главная
                </MenuLink>
            </AntMenu.Item>
            {
                getPermission(user.type, ['admin']) && (
                    <AntMenu.Item key={`/admin`}>
                        <MenuLink to={`/admin`}>
                            Администраторы
                        </MenuLink>
                    </AntMenu.Item>
                )
            }
            {
                getPermission(user.type, ['admin', 'moderator']) && (
                    <AntMenu.Item key={`/user`}>
                        <MenuLink to={`/user`}>
                            Пользователи
                        </MenuLink>
                    </AntMenu.Item>
                )
            }
            {
                getPermission(user.type, ['admin', 'moderator']) && (
                    <AntMenu.Item key={`/news`}>
                        <MenuLink to={`/news`}>
                            Новости
                        </MenuLink>
                    </AntMenu.Item>
                )
            }
            {
                getPermission(user.type, ['admin', 'moderator']) && (
                    <AntMenu.Item key={`/ad`}>
                        <MenuLink to={`/ad`}>
                            Объявления
                        </MenuLink>
                    </AntMenu.Item>
                )
            }
            {
                getPermission(user.type, ['admin', 'moderator']) && (
                    <AntMenu.Item key={`/philanthropy`}>
                        <MenuLink to={`/philanthropy`}>
                            Благотворительность
                        </MenuLink>
                    </AntMenu.Item>
                )
            }
            {
                getPermission(user.type, ['admin', 'moderator', 'shop-owner']) && (
                    <AntMenu.Item key={`/shop`}>
                        <MenuLink to={`/shop`}>
                            Магазины
                        </MenuLink>
                    </AntMenu.Item>
                )
            }
            {
                getPermission(user.type, ['shop-owner']) && (
                    <AntMenu.Item key={`/product`}>
                        <MenuLink to={`/product`}>
                            Товары
                        </MenuLink>
                    </AntMenu.Item>
                )
            }
            {
                getPermission(user.type, ['admin', 'moderator', 'shop-owner']) && (
                    <AntMenu.Item key={`/stock`}>
                        <MenuLink to={`/stock`}>
                            Акции
                        </MenuLink>
                    </AntMenu.Item>
                )
            }
        </AntMenu >
    )
}

const MenuLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        color: white;
    }
`
