export const ROLES = {
    god: 'Главный админ',
    admin: 'Администратор',
    moderator: "Модератор",
    "shop-owner": "Владелец магазина"
}
export const ROLES_TO_SHARE = {
    admin: 'Администратор',
    moderator: "Модератор",
    "shop-owner": "Владелец магазина"
}
export const SHOP_CATEGORIES = [
    'Телефоны и аксессуары',
    'Компьютеры и оргтехника',
    'Электроника',
    'Бытовая техника',
    'Одежда',
    'Всё для детей',
    'Украшения и часы',
    'Сумки и обувь',
    'Дом и зоотовары',
    'Автотовары',
    'Красота и здоровье',
    'Спорт и развлечения'
]