import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/ru_RU'

import { GlobalStyles } from './components'

import moment from 'moment'
import 'moment/locale/ru'

import apolloClient from './utils/apollo'

import Layout from './pages/layout'
import HomePage from './pages/home'
import LoginPage from './pages/login'
import ForgotPage from './pages/forgot'

import AdminsPage from './pages/admin'
import AddAdminPage from './pages/admin/add'
import EditAdminPage from './pages/admin/edit'

import UsersPage from './pages/user'

import NewsPage from './pages/news'
import AddNewsPage from './pages/news/add'
import EditNewsPage from './pages/news/edit'

import AdList from './pages/ad'

import PhilanthropyList from './pages/philanthropy'
import AddPhilanthropy from './pages/philanthropy/add'
import EditPhilanthropy from './pages/philanthropy/edit'

import ShopsList from './pages/shop'
import AddShop from './pages/shop/add'
import SingleShop from './pages/shop/single'
import EditShop from './pages/shop/edit'

import ProductsList from './pages/product'
import AddProduct from './pages/product/add'
import EditProduct from './pages/product/edit'

import StockList from './pages/stock'
import CreateStock from './pages/stock/add'
import SingleStock from './pages/stock/single'
import EditStock from './pages/stock/edit'

moment.locale('ru')

const App = () => {
    return (
        <StrictMode>
            <ApolloProvider client={apolloClient}>
                <ConfigProvider locale={locale}>
                    <Router>
                        <Routes>
                            <Route
                                path='/'
                                exact
                                element={
                                    <Layout roles={['all']}>
                                        <HomePage />
                                    </Layout> // default permossion is ['admin']
                                }
                            />
                            <Route
                                path='/login'
                                exact
                                element={
                                    <LoginPage />
                                }
                            />
                            <Route
                                path='/forgot'
                                exact
                                element={
                                    <ForgotPage />
                                }
                            />
                            <Route
                                path='/admin'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <AdminsPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/admin/add'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <AddAdminPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/admin/:id'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <EditAdminPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/user'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <UsersPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/news'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <NewsPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/news/add'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <AddNewsPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/news/:id'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <EditNewsPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/ad'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator', 'shop-owner']}>
                                        <AdList />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/philanthropy'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <PhilanthropyList />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/philanthropy/add'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <AddPhilanthropy />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/philanthropy/:id'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <EditPhilanthropy />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/shop'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator', 'shop-owner']}>
                                        <ShopsList />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/shop/add'
                                exact
                                element={
                                    <Layout roles={['shop-owner']}>
                                        <AddShop />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/shop/:id'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <SingleShop />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/shop/edit/:id'
                                exact
                                element={
                                    <Layout roles={['shop-owner']}>
                                        <EditShop />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/product'
                                exact
                                element={
                                    <Layout roles={['shop-owner']}>
                                        <ProductsList />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/product/add'
                                exact
                                element={
                                    <Layout roles={['shop-owner']}>
                                        <AddProduct />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/product/:id'
                                exact
                                element={
                                    <Layout roles={['shop-owner']}>
                                        <EditProduct />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/stock'
                                exact
                                element={
                                    <Layout roles={['shop-owner', 'admin', 'moderator']}>
                                        <StockList />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/stock/add'
                                exact
                                element={
                                    <Layout roles={['shop-owner']}>
                                        <CreateStock />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/stock/edit/:id'
                                exact
                                element={
                                    <Layout roles={['shop-owner']}>
                                        <EditStock />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/stock/:id'
                                exact
                                element={
                                    <Layout roles={['admin', 'moderator']}>
                                        <SingleStock />
                                    </Layout>
                                }
                            />
                        </Routes>
                    </Router>
                    <GlobalStyles />
                </ConfigProvider>
            </ApolloProvider>
        </StrictMode>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))
