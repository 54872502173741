import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import moment from 'moment'

import { FIND_ME_ADMIN } from '../gqls'

export const useUser = (params = {}) => {
    const { data, loading, error } = useQuery(FIND_ME_ADMIN, {
        ...params,
        fetchPolicy: 'cache-and-network'
    })

    const user = data ? data.findMeAdmin : null //do some query to get current user

    return {
        loading,
        error,
        user
    }
}

export const getPermission = (userType = '', permissionTypes = []) => {
    return userType === 'god' || permissionTypes.includes('all') || permissionTypes.includes(userType)
}

export const useRouteQuery = () => {
    const { search } = useLocation()
    const urlSearchParams = useMemo(() => new URLSearchParams(search), [search])
    return Object.fromEntries(urlSearchParams.entries())
}

export const timeFromDuration = (duration) => {
    return moment.utc(moment.duration(duration, "minutes").as("milliseconds"))
}

export const useNavigateSearch = () => {
    const navigate = useNavigate()
    return (pathname, params) => {
        Object.keys(params).forEach(key => {
            if (params[key] === null || params[key] === undefined || params[key] === '' || params[key] === NaN) {
                delete params[key]
            }
        })
        navigate({ pathname, search: `?${createSearchParams(params)}` })
    }
}

export const parseBoolean = (value) => {
    if (typeof value === 'boolean'){
        return value
    }
    if (value == 'true') {
        return true
    }
    if (value == 'false') {
        return false
    }
    return undefined
}