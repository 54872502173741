import { useMemo } from 'react'
import styled from 'styled-components'
import {
    Table,
    Popconfirm,
    Button,
    Switch,
    message,
    Form as AntForm,
    Select,
    Input,
    Tag
} from "antd"
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useQuery } from '@apollo/client'

import { Top, Image } from '../../components'
import { useNavigateSearch, useRouteQuery, parseBoolean, useUser, getPermission } from "../../utils/hooks"
import { FIND_MANY_STOCK, FIND_MANY_STOCK_COUNT } from '../../gqls'

const limit = 20

const StockList = () => {
    const query = useRouteQuery()
    const { page = 1, search = '', publish = false } = query
    const navigate = useNavigateSearch()
    const { user } = useUser()

    const isShopOwner = getPermission(user.type, ['shop-owner'])

    const variables = useMemo(() => ({
        where: {
            delete: { equals: false },
            publish: { equals: true },
            authorId: isShopOwner ? { equals: user ? user.id : '' } : undefined
        }
    }), [user, isShopOwner])

    const { data, loading } = useQuery(FIND_MANY_STOCK, {
        variables: {
            ...variables,
            take: 10,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
    })
    const { data: countData, loading: countLoading } = useQuery(FIND_MANY_STOCK_COUNT, {
        variables,
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/stock`, { ...query, page: current })
    }

    const renderColumns = useMemo(() => {
        let columns = [
            {
                title: 'Название',
                dataIndex: 'title',
                key: 'title',
                render: (title, obj) => (
                    <Link to={!isShopOwner ? obj.id : `edit/${obj.id}`}>
                        {title}
                    </Link>
                )
            },
            {
                title: 'Текст',
                dataIndex: 'text',
                key: 'text',
                render: (text, obj) => (
                    <span
                        className='number-of-lines-2'
                        style={{
                            maxWidth: 300
                        }}
                    >
                        {text}
                    </span>
                )
            },
            {
                title: 'Статус',
                dataIndex: 'publish',
                key: 'publish',
                render: (publish) => (
                    <Tag color={publish ? "green" : "red"}>{publish ? "Опубликована" : "Не опубликована"}</Tag>
                )
            },
            {
                title: 'Дата создание',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (createdAt, obj) => (
                    <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                )
            },
        ]

        if (!isShopOwner) {
            columns.splice(2, 0,
                {
                    title: 'Автор',
                    dataIndex: 'author',
                    key: 'author',
                    render: (author, obj) => (
                        <Link to={`/admin/${author.id}`}>
                            {author.name}
                        </Link>
                    )
                }
            )
        }

        return columns
    }, [isShopOwner])

    const stocks = useMemo(() => data ? data.findManyStock : [], [data])
    const stocksCount = useMemo(() => countData ? countData.findManyStockCount : [], [countData])

    return (
        <>
            <Top
                title={"Объявления"}
                action={
                    isShopOwner && (
                        <Link to='add'>
                            <Button>
                                + Добавить
                            </Button>
                        </Link>
                    )
                }
            />
            <Table
                loading={loading || countLoading}
                rowKey={(obj) => obj.id}
                dataSource={stocks}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: page,
                    total: stocksCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={renderColumns}
            />
        </>
    )
}

export default StockList