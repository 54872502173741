import { useCallback, useMemo } from "react"
import styled from "styled-components"
import {
    Table,
    Popconfirm,
    Button,
    Switch,
    message,
    Form as AntForm,
    Select,
    Input,
    Tag
} from "antd"
import { Link } from "react-router-dom"
import moment from "moment"
import { useQuery } from "@apollo/client"

import { Image, Top } from "../../components"
import { FIND_MANY_SHOP, FIND_MANY_SHOP_COUNT } from "../../gqls"
import { useNavigateSearch, useRouteQuery, parseBoolean, timeFromDuration, useUser, getPermission } from "../../utils/hooks"

const limit = 20

const Filters = styled(AntForm)`
    margin-bottom: 40px;
    margin-top: 20px;

    .item {
        width: 200px;
    }
`

const NewsList = () => {
    const query = useRouteQuery()
    const { page = 1, search = '', publish = undefined } = query
    const navigate = useNavigateSearch()
    const { user } = useUser()

    const isShopOwner = getPermission(user.type, ['shop-owner'])

    const variables = useMemo(() => ({
        where: {
            delete: { equals: false },
            publish: typeof parseBoolean(publish) === 'boolean' ? { equals: parseBoolean(publish) } : undefined,
            OR: search ? [
                { title: { contains: search, mode: 'insensitive' } },
                { content: { contains: search, mode: 'insensitive' } },
            ] : undefined,
            ownerId: isShopOwner ? { equals: user.id } : undefined
        }
    }), [search, publish])

    const { data, loading } = useQuery(FIND_MANY_SHOP, {
        variables: {
            ...variables,
            take: 10,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
        fetchPolicy: 'network-only'
    })

    const { data: countData, loading: countLoading } = useQuery(FIND_MANY_SHOP_COUNT, {
        variables,
        fetchPolicy: 'network-only'
    })

    const renderColumns = useMemo(() => {
        let arr = [
            {
                title: 'Изображение',
                dataIndex: 'logo',
                key: 'logo',
                render: (logo, obj) => (
                    <Link to={isShopOwner ? `edit/${obj.id}` : obj.id}>
                        <Image
                            src={`/uploads/${logo}`}
                            style={{
                                maxWidth: 70,
                                maxHeight: 70,
                                borderRadius: '50%'
                            }}
                        />
                    </Link>
                ),
                width: 70
            },
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                render: (name, obj) => (
                    <Link to={isShopOwner ? `edit/${obj.id}` : obj.id}>
                        {name}
                    </Link>
                )
            },
            {
                title: 'Статус',
                dataIndex: 'publish',
                key: 'publish',
                render: (publish) => (
                    <Tag color={publish ? "green" : "red"}>{publish ? "Опубликована" : "Не опубликована"}</Tag>
                )
            },
            {
                title: 'Дата создание',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (createdAt, obj) => (
                    <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                )
            },
        ]
        if (!isShopOwner) {
            arr.splice(2, 0,
                {
                    title: 'Владелец',
                    dataIndex: 'owner',
                    key: 'owner',
                    render: (owner, obj) => (
                        <Link to={`/admin/${owner.id}`}>
                            {owner.name}
                        </Link>
                    )
                }
            )
        }
        return arr
    }, [isShopOwner])

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/shop`, { ...query, page: current })
    }

    const onSubmitSearch = ({ search, publish }) => {
        navigate("/shop", { ...query, search, publish })
    }

    const shops = useMemo(() => data ? data.findManyShop : [], [data])
    const shopsCount = useMemo(() => countData ? countData.findManyShopCount : 0, [countData])

    return (
        <>
            <Top
                title={"Новости"}
                action={
                    isShopOwner && (
                        <Link to='add'>
                            <Button>
                                + Добавить
                            </Button>
                        </Link>
                    )
                }
            />
            <Filters
                layout='inline'
                onFinish={onSubmitSearch}
                initialValues={{
                    search,
                    publish: parseBoolean(publish)
                }}
            >
                <Filters.Item name={'search'}>
                    <Input
                        placeholder="Поиск"
                        allowClear
                        onChange={e => {
                            if (!e.target.value) {
                                navigate("/shop", { ...query, search: '' })
                            }
                        }}
                        className='item'
                    />
                </Filters.Item>
                <Filters.Item name={'publish'}>
                    <Select
                        placeholder="Статус публикации"
                        // onChange={data => setBlock(data)}
                        className='item'
                        allowClear
                        onClear={() => navigate("/shop", { ...query, publish: undefined })}
                    >
                        <Select.Option value={false}>
                            Не опубликованный
                        </Select.Option>
                        <Select.Option value={true}>
                            Опубликованный
                        </Select.Option>
                    </Select>
                </Filters.Item>
                <Button
                    htmlType='submit'
                    type='primary'
                >
                    Применить
                </Button>
            </Filters>
            <Table
                loading={loading || countLoading}
                rowKey={(obj) => obj.id}
                dataSource={shops}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: page,
                    total: shopsCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={renderColumns}
            />
        </>
    )
}

export default NewsList