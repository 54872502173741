import { gql } from "@apollo/client"

export const FIND_MANY_USER_COUNT = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findManyUserCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_ADMIN_COUNT = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminScalarFieldEnum]
	){
		findManyAdminCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_NEWS_COUNT = gql`
	query(
		$where: NewsWhereInput
		$orderBy: [NewsOrderByWithRelationInput]
		$cursor: NewsWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [NewsScalarFieldEnum]
	){
		findManyNewsCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_STOCK_COUNT = gql`
	query(
		$where: StockWhereInput
		$orderBy: [StockOrderByWithRelationInput]
		$cursor: StockWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [StockScalarFieldEnum]
	){
		findManyStockCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_PHILANTHROPY_COUNT = gql`
	query(
		$where: PhilanthropyWhereInput
		$orderBy: [PhilanthropyOrderByWithRelationInput]
		$cursor: PhilanthropyWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PhilanthropyScalarFieldEnum]
	){
		findManyPhilanthropyCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_AD_COUNT = gql`
	query(
		$where: AdWhereInput
		$orderBy: [AdOrderByWithRelationInput]
		$cursor: AdWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdScalarFieldEnum]
	){
		findManyAdCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_SHOP_COUNT = gql`
	query(
		$where: ShopWhereInput
		$orderBy: [ShopOrderByWithRelationInput]
		$cursor: ShopWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ShopScalarFieldEnum]
	){
		findManyShopCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_PRODUCT_COUNT = gql`
	query(
		$where: ProductWhereInput
		$orderBy: [ProductOrderByWithRelationInput]
		$cursor: ProductWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ProductScalarFieldEnum]
	){
		findManyProductCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`