import { gql } from "@apollo/client"

export const DELETE_MANY_USER = gql`
	mutation(
		$where: UserWhereInput
	){
		deleteManyUser(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_USER = gql`
	mutation(
		$data: UserUpdateManyMutationInput!
		$where: UserWhereInput
	){
		updateManyUser(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_ADMIN = gql`
	mutation(
		$where: AdminWhereInput
	){
		deleteManyAdmin(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_ADMIN = gql`
	mutation(
		$data: AdminUpdateManyMutationInput!
		$where: AdminWhereInput
	){
		updateManyAdmin(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_NEWS = gql`
	mutation(
		$where: NewsWhereInput
	){
		deleteManyNews(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_NEWS = gql`
	mutation(
		$data: NewsUpdateManyMutationInput!
		$where: NewsWhereInput
	){
		updateManyNews(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_STOCK = gql`
	mutation(
		$where: StockWhereInput
	){
		deleteManyStock(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_STOCK = gql`
	mutation(
		$data: StockUpdateManyMutationInput!
		$where: StockWhereInput
	){
		updateManyStock(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_PHILANTHROPY = gql`
	mutation(
		$where: PhilanthropyWhereInput
	){
		deleteManyPhilanthropy(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_PHILANTHROPY = gql`
	mutation(
		$data: PhilanthropyUpdateManyMutationInput!
		$where: PhilanthropyWhereInput
	){
		updateManyPhilanthropy(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_AD = gql`
	mutation(
		$where: AdWhereInput
	){
		deleteManyAd(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_AD = gql`
	mutation(
		$data: AdUpdateManyMutationInput!
		$where: AdWhereInput
	){
		updateManyAd(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_SHOP = gql`
	mutation(
		$where: ShopWhereInput
	){
		deleteManyShop(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_SHOP = gql`
	mutation(
		$data: ShopUpdateManyMutationInput!
		$where: ShopWhereInput
	){
		updateManyShop(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_PRODUCT = gql`
	mutation(
		$where: ProductWhereInput
	){
		deleteManyProduct(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_PRODUCT = gql`
	mutation(
		$data: ProductUpdateManyMutationInput!
		$where: ProductWhereInput
	){
		updateManyProduct(
			data: $data
			where: $where
		){
			count
		}
	}
`