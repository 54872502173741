import { gql } from "@apollo/client"

export const FIND_UNIQUE_PHILANTHROPY = gql`
	query(
		$where: PhilanthropyWhereUniqueInput!
	){
		findUniquePhilanthropy(
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			logo
			phone
			email
			links
			background
		}
	}
`
export const FIND_FIRST_PHILANTHROPY = gql`
	query(
		$where: PhilanthropyWhereInput
		$orderBy: [PhilanthropyOrderByWithRelationInput]
		$cursor: PhilanthropyWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PhilanthropyScalarFieldEnum]
	){
		findFirstPhilanthropy(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			logo
			phone
			email
			links
			background
		}
	}
`
export const FIND_MANY_PHILANTHROPY = gql`
	query(
		$where: PhilanthropyWhereInput
		$orderBy: [PhilanthropyOrderByWithRelationInput]
		$cursor: PhilanthropyWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PhilanthropyScalarFieldEnum]
	){
		findManyPhilanthropy(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			logo
			phone
			email
			links
			background
		}
	}
`
export const CREATE_ONE_PHILANTHROPY = gql`
	mutation(
		$data: PhilanthropyCreateInput!
	){
		createOnePhilanthropy(
			data: $data
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			logo
			phone
			email
			links
			background
		}
	}
`
export const UPDATE_ONE_PHILANTHROPY = gql`
	mutation(
		$data: PhilanthropyUpdateInput!
		$where: PhilanthropyWhereUniqueInput!
	){
		updateOnePhilanthropy(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			logo
			phone
			email
			links
			background
		}
	}
`
export const DELETE_ONE_PHILANTHROPY = gql`
	mutation(
		$where: PhilanthropyWhereUniqueInput!
	){
		deleteOnePhilanthropy(
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			logo
			phone
			email
			links
			background
		}
	}
`
export const UPSERT_ONE_PHILANTHROPY = gql`
	mutation(
		$where: PhilanthropyWhereUniqueInput!
		$create: PhilanthropyCreateInput!
		$update: PhilanthropyUpdateInput!
	){
		upsertOnePhilanthropy(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			logo
			phone
			email
			links
			background
		}
	}
`