import { useMemo } from "react"
import styled from "styled-components"
import { useMutation, useQuery } from '@apollo/client'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
    Descriptions as AntDesc,
    Popconfirm,
    Tag,
    Button,
    message
} from "antd"

import { LoadingView, Empty, Top, Image } from "../../components"
import { FIND_UNIQUE_SHOP, UPDATE_ONE_SHOP } from "../../gqls"
import { timeFromDuration } from "../../utils/hooks"

const Descriptions = styled(AntDesc)`
    max-width: 600px;
    font-size: 14px;
    .logo {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
    }
`

const Controls = styled.div`
    margin-top: 15px;
`

const SingleShop = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const [updateShop, { loading: updateLoading }] = useMutation(UPDATE_ONE_SHOP, {
        onCompleted: ({ updateOneShop }) => {
            if (updateOneShop.delete){
                message.success("Магазин удален")
                navigate('/shop')
                return 
            }
            message.success("Магазин обновлен")
            navigate('/shop')
        }
    })

    const { data, loading } = useQuery(FIND_UNIQUE_SHOP, {
        variables: {
            where: { id }
        }
    })

    const shop = useMemo(() => data ? data.findUniqueShop : null, [data])

    if (!shop && loading) {
        return <LoadingView loading />
    }
    if (!shop && !loading) {
        return <Empty description="Магазин не найден" />
    }
    if (shop && !loading) {
        return (
            <>
                <Top title={`Магазин "${shop.name}"`} />
                <Descriptions
                    title="Информация"
                    bordered
                    column={1}
                    layout='vertical'
                    labelStyle={{
                        fontWeight: 600
                    }}
                >
                    <Descriptions.Item label='Логотип'>
                        <Image
                            src={`/uploads/${shop.logo}`}
                            className='logo'
                            alt={shop.logo}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Статус пубоикации'>
                        <Tag color={shop.publish ? "green" : "red"}>{shop.publish ? "Опубликована" : "Не опубликована"}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label='Название'>
                        {shop.name}
                    </Descriptions.Item>
                    <Descriptions.Item label='Описание'>
                        {shop.description}
                    </Descriptions.Item>
                    <Descriptions.Item label='Время работы'>
                        {timeFromDuration(shop.startWork).format("HH:mm")} - {timeFromDuration(shop.endWork).format("HH:mm")}
                    </Descriptions.Item>
                    <Descriptions.Item label='Владелец'>
                        <Link to={`/admin/${shop.owner.id}`}>
                            {shop.owner.name}
                        </Link>
                    </Descriptions.Item>
                </Descriptions>
                <Controls>
                    {/* <Link to={`edit`}>
                        <Button type='primary' style={{ marginRight: 15 }}>
                            Редактировать
                        </Button>
                    </Link> */}
                    <Popconfirm
                        title='Удалить магазин?'
                        onConfirm={() => {
                            updateShop({
                                variables: {
                                    where: { id },
                                    data: {
                                        delete: { set: true }
                                    }
                                }
                            })
                        }}
                        disabled={updateLoading}
                    >
                        <Button
                            style={{ marginRight: 15 }}
                            type='danger'
                            loading={updateLoading}
                        >
                            Удалить
                        </Button>
                    </Popconfirm>
                    <Button
                        loading={updateLoading}
                        style={{ marginRight: 15 }}
                        onClick={() => {
                            updateShop({
                                variables: {
                                    where: { id },
                                    data: {
                                        publish: { set: !shop.publish }
                                    }
                                }
                            })
                        }}
                    >
                        {shop.publish ? "Снять с публикации" : "Опубликовать"}
                    </Button>
                </Controls>
            </>
        )
    }

    return null
}

export default SingleShop