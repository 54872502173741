import { useEffect } from 'react'
import styled from 'styled-components'
import MyEditor from 'suneditor-react'
import plagins from 'suneditor/src/plugins'
import { useMutation } from '@apollo/client'

import { UPLOAD_FILE } from '../gqls'
import { host } from '../utils/apollo'

const SunEditor = styled(MyEditor)`
`

export const Editor = ({ ...props }) => {

    useEffect(() => {
        const elem = document.getElementsByClassName("se-wrapper")[0]
        elem.id += "se-custom"
    }, [])

    const [upload] = useMutation(UPLOAD_FILE)

    return (
        <SunEditor
            height={400}
            lang='ru'
            setAllPlugins={true}
            setOptions={{
                plugins: plagins,
                // defaultTag: 'div',
                buttonList: [
                    ['undo', 'redo'],
                    ['fontSize', 'formatBlock'],
                    ['blockquote'],
                    [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript'
                    ],
                    ['fontColor', 'hiliteColor'],
                    ['removeFormat'],
                    '/', // Line break
                    ['outdent', 'indent'],
                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                    ['table', 'link', 'image', 'video'],
                    ['showBlocks', 'codeView', 'fullScreen', 'preview'],
                ],
                videoFileInput: true,
                imageFileInput: true,
                charCounter: true,
                fontSize: [12, 14, 16, 18, 20, 22, 24, 26, 28, 36],
                fontSizeUnit: 'px',
                linkTargetNewWindow: true,
                imageUploadUrl: '/upload',
                videoUploadUrl: '/upload',
                imageMultipleFile: false
            }}
            onImageUploadBefore={async (files, info, uploadHandler) => {
                try {
                    const [file] = files
                    const { data: { uploadFile } } = await upload({ variables: { file } })
                    uploadHandler({
                        result: [
                            {
                                url: `${host}/uploads/${uploadFile}`,
                                name: file.name,
                                size: file.size
                            }
                        ]
                    })
                } catch (e) {
                    uploadHandler(e.toString())
                }
                return null
            }}
            onVideoUploadBefore={async (files, info, uploadHandler) => {
                try {
                    const [file] = files
                    const { data: { uploadFile } } = await upload({ variables: { file } })
                    uploadHandler({
                        result: [
                            {
                                url: `${host}/uploads/${uploadFile}`,
                                name: file.name,
                                size: file.size
                            }
                        ]
                    })
                } catch (e) {
                    uploadHandler(e.toString())
                }
                return null
            }}
            {...props}
        />
    )
}
