import { useMemo } from 'react'
import styled from 'styled-components'
import {
    Table,
    Tag,
    Button
} from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useQuery } from '@apollo/client'

import { Top } from '../../components'
import { useNavigateSearch, useRouteQuery, parseBoolean, useUser, getPermission } from "../../utils/hooks"
import { FIND_MANY_PRODUCT, FIND_MANY_PRODUCT_COUNT } from '../../gqls'

const limit = 20

const ProductsList = () => {
    const query = useRouteQuery()
    const { page = 1, search = '', publish = true } = query
    const navigate = useNavigateSearch()
    const { user } = useUser()

    const isShopOwner = getPermission(user.type, ['shop-owner'])

    const variables = useMemo(() => ({
        where: {
            delete: { equals: false },
            shop: isShopOwner ? { ownerId: { equals: user ? user.id : '' } } : undefined
        }
    }), [isShopOwner])

    const { data, loading } = useQuery(FIND_MANY_PRODUCT, {
        variables: {
            ...variables,
            take: 10,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
        fetchPolicy: "network-only"
    })

    const { data: countData, loading: countLoading } = useQuery(FIND_MANY_PRODUCT_COUNT, {
        variables,
        fetchPolicy: "network-only"
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/product`, { ...query, page: current })
    }

    const products = useMemo(() => data ? data.findManyProduct : [], [data])
    const productsCount = useMemo(() => countData ? countData.findManyProductCount : [], [countData])

    return (
        <>
            <Top
                title={"Список товаров"}
                action={
                    isShopOwner && (
                        <Link to='add'>
                            <Button>
                                + Добавить
                            </Button>
                        </Link>
                    )
                }
            />
            <Table
                loading={loading || countLoading}
                rowKey={(obj) => obj.id}
                dataSource={products}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: page,
                    total: productsCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'Название',
                        dataIndex: 'name',
                        key: 'name',
                        render: (title, obj) => (
                            <Link to={obj.id}>
                                {title}
                            </Link>
                        )
                    },
                    {
                        title: 'Описание',
                        dataIndex: 'description',
                        key: 'description',
                        render: (description, obj) => (
                            <span
                                className='number-of-lines-2'
                                style={{
                                    maxWidth: 300
                                }}
                            >
                                {description}
                            </span>
                        )
                    },
                    {
                        title: 'Магазины',
                        dataIndex: 'shop',
                        key: 'shop',
                        render: (shop) => (
                            <Link to={`/shop/edit/${shop.id}`}>{shop.name}</Link>
                        )
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'publish',
                        key: 'publish',
                        render: (publish) => (
                            <Tag color={publish ? "green" : "red"}>{publish ? "Опубликована" : "Не опубликована"}</Tag>
                        )
                    },
                    {
                        title: 'Дата создание',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt, obj) => (
                            <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                        )
                    },
                ]}
            />
        </>
    )
}

export default ProductsList