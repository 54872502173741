import { gql } from "@apollo/client"

export const SEND_USER_CODE = gql`
	mutation(
		$data: UserSendPhoneAndCodeInput!
	){
		sendUserCode(
			data: $data
		){
			user{
				id
				createdAt
				updatedAt
				name
				phone
				email
				block
				delete
				avatar
			}
			token
		}
	}
`