import { gql } from "@apollo/client"

export const FIND_UNIQUE_PRODUCT = gql`
	query(
		$where: ProductWhereUniqueInput!
	){
		findUniqueProduct(
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			images
			categories
			price
			shop{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				startWork
				endWork
				_count{
					products
				}
			}
		}
	}
`
export const FIND_FIRST_PRODUCT = gql`
	query(
		$where: ProductWhereInput
		$orderBy: [ProductOrderByWithRelationInput]
		$cursor: ProductWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ProductScalarFieldEnum]
	){
		findFirstProduct(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			images
			categories
			price
			shop{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				startWork
				endWork
				_count{
					products
				}
			}
		}
	}
`
export const FIND_MANY_PRODUCT = gql`
	query(
		$where: ProductWhereInput
		$orderBy: [ProductOrderByWithRelationInput]
		$cursor: ProductWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ProductScalarFieldEnum]
	){
		findManyProduct(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			images
			categories
			price
			shop{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				startWork
				endWork
				_count{
					products
				}
			}
		}
	}
`
export const CREATE_ONE_PRODUCT = gql`
	mutation(
		$data: ProductCreateInput!
	){
		createOneProduct(
			data: $data
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			images
			categories
			price
			shop{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				startWork
				endWork
				_count{
					products
				}
			}
		}
	}
`
export const UPDATE_ONE_PRODUCT = gql`
	mutation(
		$data: ProductUpdateInput!
		$where: ProductWhereUniqueInput!
	){
		updateOneProduct(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			images
			categories
			price
			shop{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				startWork
				endWork
				_count{
					products
				}
			}
		}
	}
`
export const DELETE_ONE_PRODUCT = gql`
	mutation(
		$where: ProductWhereUniqueInput!
	){
		deleteOneProduct(
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			images
			categories
			price
			shop{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				startWork
				endWork
				_count{
					products
				}
			}
		}
	}
`
export const UPSERT_ONE_PRODUCT = gql`
	mutation(
		$where: ProductWhereUniqueInput!
		$create: ProductCreateInput!
		$update: ProductUpdateInput!
	){
		upsertOneProduct(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			images
			categories
			price
			shop{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				startWork
				endWork
				_count{
					products
				}
			}
		}
	}
`