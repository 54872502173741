import {
    Upload
} from 'antd'
import { useMutation } from '@apollo/client'

import { UPLOAD_FILE, DELETE_FILE } from '../gqls'

export const UploadFile = ({
    onChange = () => { },
    value,
    children,
    ...props
}) => {

    const [uploadFile] = useMutation(UPLOAD_FILE, {
        onCompleted: ({ uploadFile }) => {
            onChange(uploadFile)
        },
        onError: () => {
            onChange(null)
        }
    })

    const [deleteFile] = useMutation(DELETE_FILE, {
        onCompleted: () => {
        },
        onError: () => {
        }
    })

    const onRemove = () => {
        if (value) {
            deleteFile({
                variables: { fileName: value }
            })
            onChange(null)
        }
    }

    const customRequest = async ({ onSuccess, onError, file }) => {
        onRemove()
        const { data, errors } = await uploadFile({
            variables: { file }
        })
        if (data) {
            onSuccess('Ok')
        }
        if (errors) {
            onError(errors)
        }
    }

    // const child = useMemo(() => value ? null : children ? children : 'Выбрать изображение', [children, value])

    return (
        <Upload
            showUploadList={{
                showPreviewIcon: false,
                showRemoveIcon: true
            }}
            // listType='picture-card'
            customRequest={customRequest}
            multiple={false}
            maxCount={1}
            onRemove={onRemove}
            accept="image/*"
            {...props}
        >
            {children}
        </Upload>
    )
}