import { useMemo, useState } from "react"
import styled from "styled-components"
import { Form as AntForm, Button, Input, Select, message, Upload, Popconfirm } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { UPLOAD_FILE, DELETE_FILE, UPDATE_ONE_PRODUCT, FIND_MANY_SHOP, FIND_UNIQUE_PRODUCT, FIND_UNIQUE_SHOP_CATEGORIES } from '../../gqls'
import { useNavigate, useParams } from "react-router-dom"

import { LoadingView, Top } from "../../components"
import { useUser } from "../../utils/hooks"

const Form = styled(AntForm)`
    max-width: 600px;
    .upload-container {
        display: flex;
        border: 1px dashed #d9d9d9;
        position: relative;
        overflow: hidden;
        align-items: center;
        box-sizing: border-box;
        width: fit-content;

        .ant-upload {
            width: 100%;
        }

        .upload {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            height: fit-content;
            font-size: 17px;
            height: 100%;
        }

        .mask {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
            color: #fff;
            font-size: 17px;
            padding: 15px;
            box-sizing: border-box;
        }

        :hover {
            .mask {
                display: flex;
            }
        }
    }
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const EditProduct = () => {
    const [form] = Form.useForm()
    const [fileList, setFileList] = useState([])
    const { user } = useUser()
    const { id } = useParams()
    const navigate = useNavigate()

    const [getShopCategories, { data: categoriesData, loading: categoriesLoading }] = useLazyQuery(FIND_UNIQUE_SHOP_CATEGORIES, {
        fetchPolicy: "network-only"
    })

    const { data, loading } = useQuery(FIND_UNIQUE_PRODUCT, {
        variables: {
            where: { id }
        },
        onCompleted: ({
            findUniqueProduct: {
                name,
                description,
                images,
                shop,
                categories,
                price
            }
        }) => {
            const productFileList = images.map(item => ({
                uid: item,
                url: `/uploads/${item}`,
                name: item
            }))
            form.setFieldsValue({
                name,
                description,
                images: {
                    fileList: productFileList
                },
                shop: shop.id,
                categories,
                price
            })
            getShopCategories({
                variables: {
                    where: { id: shop.id }
                }
            })
            setFileList(productFileList)
        }
    })

    const product = useMemo(() => data ? data.findUniqueProduct : null, [data])

    const { data: shopData, loading: shopLaoding } = useQuery(FIND_MANY_SHOP, {
        variables: {
            where: {
                delete: { equals: false },
                ownerId: { equals: user ? user.id : "" }
            }
        }
    })

    const [updatePrroduct, { loading: updateLoading }] = useMutation(UPDATE_ONE_PRODUCT, {
        onCompleted: () => {
            message.success("товар обновлен")
            setFileList([])
            form.resetFields()
            navigate("/product")
        }
    })

    const [uploadFile, { loading: uploadLoading }] = useMutation(UPLOAD_FILE, {
        onCompleted: ({ uploadFile }) => {
        },
        onError: () => {
        }
    })

    const [deleteFile, { loading: removeLoading }] = useMutation(DELETE_FILE, {
        onCompleted: () => {
        },
        onError: () => {
        }
    })

    const onRemove = ({ name, uid }) => {
        if (name) {
            deleteFile({
                variables: { fileName: name }
            })
        }
        setFileList(list => list.filter(item => item.uid !== uid))
    }

    const customRequest = async ({ onSuccess, onError, file }) => {
        const newFile = {
            ...file,
            status: 'uploading',
        }
        setFileList(list => [...list, newFile])
        const { data, errors } = await uploadFile({
            variables: { file }
        })
        // console.log(data, file)
        if (data) {
            const updatedFile = {
                ...file,
                status: 'done',
                url: `/uploads/${data.uploadFile}`,
                name: data.uploadFile
            }
            setFileList(list => list.map(item => item.uid === newFile.uid ? updatedFile : item))
            onSuccess('Ok')
        }
        if (errors) {
            onError('Ошибка загрузки')
        }
    }

    const handleSubmit = ({ shop, name, description, categories, price }) => {
        const data = {
            name: { set: name },
            description: { set: description },
            images: fileList.map(item => item.name),
            shop: {
                connect: { id: shop }
            },
            categories,
            price: { set: price }
        }
        updatePrroduct({
            variables: {
                where: { id },
                data
            }
        })
    }

    const shops = useMemo(() => shopData ? shopData.findManyShop : [], [shopData])
    const categories = useMemo(() => categoriesData ? categoriesData.findUniqueShopCategories : [], [categoriesData])

    return (
        <>
            <Top title={"Добавление товара"} />
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Form.Item
                    name={"name"}
                    rules={[rules.required]}
                    label="Название организации"
                >
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item
                    name={"description"}
                    rules={[rules.required]}
                    label="Описание"
                >
                    <Input.TextArea
                        placeholder='Введеите описание'
                        style={{
                            minHeight: 200
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={"price"}
                    rules={[rules.required]}
                    label="Цена"
                >
                    <Input placeholder="Укажите цену" type='number' />
                </Form.Item>
                <Form.Item
                    name={"images"}
                    rules={[rules.required]}
                    label="Изображения"
                >
                    <Upload
                        fileList={fileList}
                        showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true
                        }}
                        listType='picture-card'
                        customRequest={customRequest}
                        multiple={false}
                        onRemove={onRemove}
                        accept="image/*"
                    >
                        <div>
                            {uploadLoading || removeLoading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div style={{ marginTop: 8 }}>{uploadLoading || removeLoading ? "Загрузка" : "Загрузить"}</div>
                        </div>
                    </Upload>
                </Form.Item>
                <Form.Item
                    name={"shop"}
                    rules={[rules.required]}
                    label="Магазин"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Select
                        placeholder='Выберите магазины'
                        loading={shopLaoding}
                    >
                        {
                            shops.map((shop) => (
                                <Select.Option key={shop.id}>
                                    {shop.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name={"categories"}
                    rules={[rules.required]}
                    label="Категории"
                >
                    <Select
                        placeholder='Выберите категории товара'
                        mode='tags'
                        loading={categoriesLoading}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                    >
                        {
                            categories.map((category) => (
                                <Select.Option key={category}>
                                    {category}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Button
                    style={{ marginRight: 15 }}
                    loading={updateLoading}
                    type="primary"
                    htmlType="submit"
                >
                    Сохранить
                </Button>
                <Popconfirm
                    title='Удалить?'
                    onConfirm={() => {
                        updatePrroduct({
                            variables: {
                                where: { id },
                                data: {
                                    delete: { set: true }
                                }
                            }
                        })
                    }}
                >
                    <Button
                        style={{ marginRight: 15 }}
                        type="danger"
                        loading={updateLoading}
                    >
                        Удалить
                    </Button>
                </Popconfirm>
                {
                    product && (
                        <Button
                            loading={updateLoading}
                            onClick={() => {
                                updatePrroduct({
                                    variables: {
                                        where: { id },
                                        data: {
                                            publish: { set: !product.publish }
                                        }
                                    }
                                })
                            }}
                        >
                            {product.publish ? "Снять с публикации" : "Опубликовать"}
                        </Button>
                    )
                }
            </Form>
            <LoadingView loading={loading} />
        </>
    )
}

export default EditProduct