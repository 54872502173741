import { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, Input, Select, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'

import { Top, UploadFile, Editor } from '../../components'
import { CREATE_ONE_NEWS } from '../../gqls'
import { useUser } from '../../utils/hooks'

const Form = styled(AntForm)`
    max-width: 600px;
    .upload-container {
        display: flex;
        border: 1px dashed #d9d9d9;
        position: relative;
        overflow: hidden;
        align-items: center;
        box-sizing: border-box;
        width: fit-content;

        .ant-upload {
            width: 100%;
        }

        .upload {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            height: fit-content;
            font-size: 17px;
            height: 100%;
        }

        .mask {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
            color: #fff;
            font-size: 17px;
            padding: 15px;
            box-sizing: border-box;
        }

        :hover {
            .mask {
                display: flex;
            }
        }
    }
`
const Image = styled.img`
    max-width: 100%;
    aspect-ratio: ${({ ratio }) => ratio};
    object-fit: cover;
    object-position: center;
    background-color: #fafafa;
`
const Note = styled.div`
    margin-bottom: 24px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const CreateNews = () => {
    const [form] = Form.useForm()
    const [image, setImage] = useState(undefined)
    const { user } = useUser()

    const [creaetNews, { loading }] = useMutation(CREATE_ONE_NEWS, {
        onCompleted: () => {
            form.resetFields()
            message.success("Новость создана")
            setImage(undefined)
        },
        onError: e => {
            message.error("Что то пошло не так, повторите попытку позже")
        }
    })

    const handleSubmit = ({ ...values }) => {
        const data = {
            ...values,
        }
        creaetNews({
            variables: {
                data
            }
        })
    }

    return (
        <>
            <Top
                title={'Создание публикации'}
            />
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Form.Item
                    name='image'
                    rules={[rules.required]}
                    label='Изображение'
                >
                    <UploadFile
                        className='upload-container'
                        onChange={name => setImage(name)}
                        itemRender={() => null}
                    >
                        {
                            image ? (
                                <div className='upload'>
                                    <Image src={`/uploads/${image}`} ratio={'3/2'} />
                                    <div className='mask'>
                                        <UploadOutlined style={{ marginRight: 5 }} />
                                        Загрузить другое изображение
                                    </div>
                                </div>
                            ) : (
                                <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
                            )
                        }
                    </UploadFile>
                </Form.Item>
                <Form.Item
                    name={"title"}
                    rules={[rules.required]}
                    label="Заголовок"
                >
                    <Input placeholder='Введите заголовок' />
                </Form.Item>
                <Form.Item
                    name={"content"}
                    rules={[rules.required]}
                    label="Контент"
                >
                    <Editor />
                </Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                    Создать
                </Button>
            </Form>
        </>
    )
}

export default CreateNews