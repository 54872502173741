import { gql } from "@apollo/client"

export const FIND_UNIQUE_NEWS = gql`
	query(
		$where: NewsWhereUniqueInput!
	){
		findUniqueNews(
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			image
			title
			content
		}
	}
`
export const FIND_FIRST_NEWS = gql`
	query(
		$where: NewsWhereInput
		$orderBy: [NewsOrderByWithRelationInput]
		$cursor: NewsWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [NewsScalarFieldEnum]
	){
		findFirstNews(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			delete
			publish
			image
			title
			content
		}
	}
`
export const FIND_MANY_NEWS = gql`
	query(
		$where: NewsWhereInput
		$orderBy: [NewsOrderByWithRelationInput]
		$cursor: NewsWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [NewsScalarFieldEnum]
	){
		findManyNews(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			delete
			publish
			image
			title
			content
		}
	}
`
export const CREATE_ONE_NEWS = gql`
	mutation(
		$data: NewsCreateInput!
	){
		createOneNews(
			data: $data
		){
			id
			createdAt
			updatedAt
			delete
			publish
			image
			title
			content
		}
	}
`
export const UPDATE_ONE_NEWS = gql`
	mutation(
		$data: NewsUpdateInput!
		$where: NewsWhereUniqueInput!
	){
		updateOneNews(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			image
			title
			content
		}
	}
`
export const DELETE_ONE_NEWS = gql`
	mutation(
		$where: NewsWhereUniqueInput!
	){
		deleteOneNews(
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			image
			title
			content
		}
	}
`
export const UPSERT_ONE_NEWS = gql`
	mutation(
		$where: NewsWhereUniqueInput!
		$create: NewsCreateInput!
		$update: NewsUpdateInput!
	){
		upsertOneNews(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			delete
			publish
			image
			title
			content
		}
	}
`