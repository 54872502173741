import { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, Input, Select, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'

import { Top, UploadFile } from '../../components'
import { CREATE_ONE_PHILANTHROPY } from '../../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
    .upload-container {
        display: flex;
        border: 1px dashed #d9d9d9;
        position: relative;
        overflow: hidden;
        align-items: center;
        box-sizing: border-box;
        width: fit-content;

        .ant-upload {
            width: 100%;
        }

        .upload {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            height: fit-content;
            font-size: 17px;
            height: 100%;
        }

        .mask {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
            color: #fff;
            font-size: 17px;
            padding: 15px;
            box-sizing: border-box;
        }

        :hover {
            .mask {
                display: flex;
            }
        }
    }
`
const Image = styled.img`
    max-width: 100%;
    aspect-ratio: ${({ ratio }) => ratio};
    object-fit: cover;
    object-position: center;
    background-color: #fafafa;
`
const Note = styled.div`
    margin-bottom: 24px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const CreatePhilanthropy = () => {
    const [form] = Form.useForm()
    const [image, setImage] = useState(undefined)
    const [background, setBackground] = useState(undefined)

    const [creaetPhilanthropy, { loading }] = useMutation(CREATE_ONE_PHILANTHROPY, {
        onCompleted: () => {
            form.resetFields()
            message.success("Организация создана")
            setImage(undefined)
            setBackground(undefined)
        },
        onError: e => {
            message.error("Что то пошло не так, повторите попытку позже")
        }
    })

    const handleSubmit = ({ ...values }) => {
        const data = {
            ...values,
        }
        // console.log(data)
        creaetPhilanthropy({
            variables: {
                data
            }
        })
    }

    return (
        <>
            <Top
                title={'Создание публикации'}
            />
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Form.Item
                    name='logo'
                    rules={[rules.required]}
                    label='Лого'
                >
                    <UploadFile
                        className='upload-container'
                        onChange={name => setImage(name)}
                        itemRender={() => null}
                    >
                        {
                            image ? (
                                <div className='upload'>
                                    <Image
                                        src={`/uploads/${image}`}
                                        ratio={'1/1'}
                                        style={{
                                            borderRadius: '50%',
                                            width: 150,
                                            height: 150
                                        }}
                                    />
                                    <div className='mask'>
                                        <UploadOutlined style={{ marginRight: 5 }} />
                                        Загрузить другое логотип
                                    </div>
                                </div>
                            ) : (
                                <Button icon={<UploadOutlined />}>Загрузить логотип</Button>
                            )
                        }
                    </UploadFile>
                </Form.Item>
                <Form.Item
                    name='background'
                    label='Фоновое изображение'
                >
                    <UploadFile
                        className='upload-container'
                        onChange={name => setBackground(name)}
                        itemRender={() => null}
                    >
                        {
                            background ? (
                                <div className='upload'>
                                    <Image
                                        src={`/uploads/${background}`}
                                        ratio={'16/9'}
                                    />
                                    <div className='mask'>
                                        <UploadOutlined style={{ marginRight: 5 }} />
                                        Загрузить другое изображение
                                    </div>
                                </div>
                            ) : (
                                <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
                            )
                        }
                    </UploadFile>
                </Form.Item>
                <Form.Item
                    name={"name"}
                    rules={[rules.required]}
                    label="Название организации"
                >
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item
                    name={"description"}
                    rules={[rules.required]}
                    label="Описание"
                >
                    <Input.TextArea
                        placeholder='Введеите описание'
                        style={{
                            minHeight: 200
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={"phone"}
                    label="Контактный телефон"
                >
                    <Input placeholder='Введите номер телефона' />
                </Form.Item>
                <Form.Item
                    name={"email"}
                    label="Адрес электронной почты"
                >
                    <Input placeholder='Введите email' />
                </Form.Item>
                <Form.Item
                    name={"links"}
                    label="Ссылки на ресурсы"
                >
                    <Select
                        mode='tags'
                        placeholder='Введите ссылки'
                    />
                </Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                    Создать
                </Button>
            </Form>
        </>
    )
}

export default CreatePhilanthropy