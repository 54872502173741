import { gql } from "@apollo/client"

export const FIND_UNIQUE_SHOP_CATEGORIES = gql`
	query($where: ShopWhereUniqueInput!) {
		findUniqueShopCategories(where: $where)
	}
`
export const FIND_UNIQUE_SHOP = gql`
	query(
		$where: ShopWhereUniqueInput!
	){
		findUniqueShop(
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			startWork
			endWork
			address
			category
			owner {
				id
				name
			}
			logo
			category
			products{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				images
			}
			_count{
				products
			}
		}
	}
`
export const FIND_FIRST_SHOP = gql`
	query(
		$where: ShopWhereInput
		$orderBy: [ShopOrderByWithRelationInput]
		$cursor: ShopWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ShopScalarFieldEnum]
	){
		findFirstShop(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			startWork
			endWork
			address
			category
			owner {
				id
				name
			}
			logo
			category
			products{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				images
			}
			_count{
				products
			}
		}
	}
`
export const FIND_MANY_SHOP = gql`
	query(
		$where: ShopWhereInput
		$orderBy: [ShopOrderByWithRelationInput]
		$cursor: ShopWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ShopScalarFieldEnum]
	){
		findManyShop(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			startWork
			endWork
			address
			category
			owner {
				id
				name
			}
			logo
			category
			products{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				images
			}
			_count{
				products
			}
		}
	}
`
export const CREATE_ONE_SHOP = gql`
	mutation(
		$data: ShopCreateInput!
	){
		createOneShop(
			data: $data
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			startWork
			endWork
			address
			category
			owner {
				id
				name
			}
			logo
			category
			products{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				images
			}
			_count{
				products
			}
		}
	}
`
export const UPDATE_ONE_SHOP = gql`
	mutation(
		$data: ShopUpdateInput!
		$where: ShopWhereUniqueInput!
	){
		updateOneShop(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			startWork
			endWork
			address
			category
			owner {
				id
				name
			}
			logo
			category
			products{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				images
			}
			_count{
				products
			}
		}
	}
`
export const DELETE_ONE_SHOP = gql`
	mutation(
		$where: ShopWhereUniqueInput!
	){
		deleteOneShop(
			where: $where
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			startWork
			endWork
			address
			category
			owner {
				id
				name
			}
			logo
			category
			products{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				images
			}
			_count{
				products
			}
		}
	}
`
export const UPSERT_ONE_SHOP = gql`
	mutation(
		$where: ShopWhereUniqueInput!
		$create: ShopCreateInput!
		$update: ShopUpdateInput!
	){
		upsertOneShop(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			delete
			publish
			name
			description
			startWork
			endWork
			address
			category
			owner {
				id
				name
			}
			logo
			category
			products{
				id
				createdAt
				updatedAt
				delete
				publish
				name
				description
				images
			}
			_count{
				products
			}
		}
	}
`