import { useMemo } from "react"
import styled from "styled-components"
import {
    Table,
    Popconfirm,
    Button,
    Switch,
    message,
    Form as AntForm,
    Select,
    Input
} from "antd"
import { Link } from "react-router-dom"
import moment from "moment"
import { useMutation, useQuery } from "@apollo/client"

import { Top } from "../../components"
import { useNavigateSearch, useRouteQuery, parseBoolean } from '../../utils/hooks'
import { FIND_MANY_USER, FIND_MANY_USER_COUNT, UPDATE_ONE_USER } from "../../gqls"
import { ROLES } from '../../utils/const'

const limit = 20

const Filters = styled(AntForm)`
    /* display: flex;
    flex-direction: row;
    align-items: center; */
    margin-bottom: 40px;
    margin-top: 20px;

    .item {
        width: 200px;
        /* margin-right: 15px; */
    }
`

const Users = () => {
    const query = useRouteQuery()
    const { page = 1, search = '', block = undefined, deleted = undefined } = query
    const navigate = useNavigateSearch()

    const variables = useMemo(() => ({
        where: {
            block: typeof parseBoolean(block) === 'boolean' ? { equals: parseBoolean(block) } : undefined,
            delete: typeof parseBoolean(deleted) === 'boolean' ? { equals: parseBoolean(deleted) } : undefined,
            phone: { not: { equals: "79991234567" } },
            OR: search ? [
                { name: { contains: search, mode: 'insensitive' } },
                { email: { contains: search, mode: 'insensitive' } },
                { phone: { contains: search, mode: 'insensitive' } },
            ] : undefined,
        }
    }), [search, block, deleted])

    const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_ONE_USER, {
        onCompleted: ({ updateOneUser: { block, delete: deleted } }) => {
            if (deleted) {
                return message.success("Пользователь удален")
            }
            if (block) {
                return message.success("Пользователь заблокирован")
            }
            message.success("Пользователь обновлен")
        }
    })

    const { data, loading } = useQuery(FIND_MANY_USER, {
        variables: {
            ...variables,
            take: 10,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
        fetchPolicy: 'network-only'
    })
    const { data: countData, loading: countLoading } = useQuery(FIND_MANY_USER_COUNT, {
        variables,
        fetchPolicy: 'network-only'
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/user`, { ...query, page: current })
    }

    const onSubmitSearch = ({ search, role, block, deleted }) => {
        navigate("/user", { ...query, search, role, block, deleted })
    }

    const users = useMemo(() => data ? data.findManyUser : [], [data])
    const usersCount = useMemo(() => countData ? countData.findManyUserCount : [], [countData])

    return (
        <>
            <Top
                title={'Пользователи'}
                // action={
                //     <Link to='add'>
                //         <Button>
                //             + Добавить
                //         </Button>
                //     </Link>
                // }
            />
            <Filters
                layout='inline'
                onFinish={onSubmitSearch}
                initialValues={{
                    search,
                    block: parseBoolean(block),
                    deleted: parseBoolean(deleted),
                    // role
                }}
            >
                <Filters.Item name={'search'}>
                    <Input
                        placeholder="Поиск"
                        allowClear
                        onChange={e => {
                            if (!e.target.value) {
                                navigate("/user", { ...query, search: '' })
                            }
                        }}
                        className='item'
                    />
                </Filters.Item>
                {/* <Filters.Item name={'role'}>
                    <Select
                        placeholder="Роль"
                        // onChange={data => setRole(data)}
                        className='item'
                        allowClear
                        onClear={() => navigate("/user", { ...query, role: undefined })}
                    >
                        {
                            Object.keys(ROLES_TO_SHARE).map(key => (
                                <Select.Option key={key}>
                                    {ROLES_TO_SHARE[key]}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Filters.Item> */}
                <Filters.Item name={'block'}>
                    <Select
                        placeholder="Статус блокировки"
                        // onChange={data => setBlock(data)}
                        className='item'
                        allowClear
                        onClear={() => navigate("/user", { ...query, block: undefined })}
                    >
                        <Select.Option value={false}>
                            Не заблокированный
                        </Select.Option>
                        <Select.Option value={true}>
                            Заблокированный
                        </Select.Option>
                    </Select>
                </Filters.Item>
                <Filters.Item name={'deleted'}>
                    <Select
                        placeholder="Статус удаления"
                        // defaultValue={false}
                        // onChange={data => setDeleted(data)}
                        className='item'
                        allowClear
                        onClear={() => navigate("/user", { ...query, deleted: undefined })}
                    >
                        <Select.Option value={false}>
                            Не удаленный
                        </Select.Option>
                        <Select.Option value={true}>
                            Удалённый
                        </Select.Option>
                    </Select>
                </Filters.Item>
                <Button
                    htmlType='submit'
                    type='primary'
                >
                    Применить
                </Button>
            </Filters>
            <Table
                loading={loading || countLoading || updateLoading}
                rowKey={(obj) => obj.id}
                dataSource={users}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: page,
                    total: usersCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'ФИО',
                        dataIndex: 'name',
                        key: 'name',
                        // render: (name, obj) => (
                        //     <Link to={obj.id}>
                        //         {name}
                        //     </Link>
                        // )
                    },
                    {
                        title: 'Телефон',
                        dataIndex: 'phone',
                        key: 'phone',
                        render: (phone) => (
                            <a href={`tel:+${phone}`}>
                                +{phone}
                            </a>
                        )
                    },
                    {
                        title: 'Дата создание',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt, obj) => (
                            <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                        )
                    },
                    {
                        title: 'Бан',
                        dataIndex: 'block',
                        key: 'block',
                        render: (block, object) => (
                            <Switch
                                size='small'
                                onChange={(value) => {
                                    updateUser({
                                        variables: {
                                            where: { id: object.id },
                                            data: {
                                                block: { set: value }
                                            }
                                        }
                                    })
                                }}
                                defaultChecked={block}
                            />
                        )
                    },
                    {
                        title: 'Действие',
                        dataIndex: 'delete',
                        key: 'delete',
                        width: 150,
                        render: (_delete, object) => (
                            <Popconfirm
                                title={`${object.delete ? "Восстановить" : "Удалить"}?`}
                                onConfirm={() => {
                                    updateUser({
                                        variables: {
                                            where: {
                                                id: object.id,
                                            },
                                            data: {
                                                delete: { set: !_delete }
                                            }
                                        }
                                    })
                                }}
                            >
                                <Button
                                    type={_delete ? 'primary' : 'danger'}
                                >
                                    {_delete ? "Восстановить" : "Удалить"}
                                </Button>
                            </Popconfirm>
                        )
                    },
                ]}
            />
        </>
    )
}

export default Users