import { useMemo, useState } from 'react'
import styled from 'styled-components'
import {
    Form as AntForm,
    Button,
    Input,
    message,
    DatePicker,
    Select
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'

import { Top, UploadFile } from '../../components'
import { CREATE_ONE_STOCK, FIND_MANY_PRODUCT, FIND_MANY_SHOP } from '../../gqls'
import { useUser } from '../../utils/hooks'
import moment from 'moment'

const Form = styled(AntForm)`
    max-width: 600px;
    .upload-container {
        display: flex;
        border: 1px dashed #d9d9d9;
        position: relative;
        overflow: hidden;
        align-items: center;
        box-sizing: border-box;
        width: fit-content;

        .ant-upload {
            width: 100%;
        }

        .upload {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            height: fit-content;
            font-size: 17px;
            height: 100%;
        }

        .mask {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
            color: #fff;
            font-size: 17px;
            padding: 15px;
            box-sizing: border-box;
        }

        :hover {
            .mask {
                display: flex;
            }
        }
    }
`
const Image = styled.img`
    max-width: 100%;
    aspect-ratio: ${({ ratio }) => ratio};
    object-fit: cover;
    object-position: center;
    background-color: #fafafa;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const CreateStock = () => {
    const [form] = Form.useForm()
    const [image, setImage] = useState(undefined)
    const [shop, setShop] = useState(undefined)
    const { user } = useUser()

    const { data, loading: productsLoading } = useQuery(FIND_MANY_PRODUCT, {
        variables: {
            where: {
                delete: { equals: false },
                publish: { equals: true },
                shopId: { equals: shop ? shop : "" }
            }
        }
    })

    const { data: shopData, loading: shopLoading } = useQuery(FIND_MANY_SHOP, {
        variables: {
            where: {
                delete: { equals: false },
                publish: { equals: true },
                owner: {
                    id: { equals: user ? user.id : "" }
                }
            }
        }
    })

    const [creaetStock, { loading }] = useMutation(CREATE_ONE_STOCK, {
        onCompleted: () => {
            form.resetFields()
            message.success("Акция создана")
            setImage(undefined)
        },
        onError: e => {
            message.error("Что то пошло не так, повторите попытку позже")
        }
    })

    const handleSubmit = ({ shop, products, deadline, ...values }) => {
        const data = {
            ...values,
            image,
            deadline: moment(deadline).endOf("day"),
            author: {
                connect: { id: user.id }
            },
            products: {
                connect: products.map(item => ({ id: item }))
            },
            shop: {
                connect: { id: shop }
            }
        }
        console.log(data)
        creaetStock({
            variables: {
                data,
            }
        })
    }

    const products = useMemo(() => data ? data.findManyProduct : [], [data])
    const shops = useMemo(() => shopData ? shopData.findManyShop : [], [shopData])

    return (
        <>
            <Top
                title={'Создание публикации'}
            />
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Form.Item
                    name='image'
                    rules={[rules.required]}
                    label='Изображение'
                >
                    <UploadFile
                        className='upload-container'
                        onChange={name => setImage(name)}
                        itemRender={() => null}
                    >
                        {
                            image ? (
                                <div className='upload'>
                                    <Image src={`/uploads/${image}`} ratio={'16/9'} />
                                    <div className='mask'>
                                        <UploadOutlined style={{ marginRight: 5 }} />
                                        Загрузить другое изображение
                                    </div>
                                </div>
                            ) : (
                                <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
                            )
                        }
                    </UploadFile>
                </Form.Item>
                <Form.Item
                    name={"title"}
                    rules={[rules.required]}
                    label="Заголовок"
                >
                    <Input placeholder='Введите заголовок' />
                </Form.Item>
                <Form.Item
                    name={"text"}
                    rules={[rules.required]}
                    label="Описание"
                >
                    <Input.TextArea
                        placeholder='Введите описание'
                        style={{ minHeight: 200 }}
                    />
                </Form.Item>
                <Form.Item
                    name={"deadline"}
                    rules={[rules.required]}
                    label="Срок публикации"
                >
                    <DatePicker
                        placeholder='Опубликовано до...'
                        format='DD.MM.yyyy'
                        style={{ width: '100%' }}
                        disabledDate={d => {
                            if (d.isAfter(moment().startOf("day"))) {
                                return false
                            }
                            return true
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={"shop"}
                    rules={[rules.required]}
                    label="Магазин"
                >
                    <Select
                        placeholder='Выберите магазин'
                        loading={shopLoading}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        onSelect={e => {
                            setShop(e)
                            form.setFieldsValue({
                                products: []
                            })
                        }}
                    >
                        {
                            shops.map(item => (
                                <Select.Option key={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                {
                    shop && (
                        <Form.Item
                            name={"products"}
                            rules={[rules.required]}
                            label="Товары"
                        >
                            <Select
                                placeholder='Выберите товары'
                                loading={productsLoading}
                                mode='multiple'
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                            >
                                {
                                    products.map(item => (
                                        <Select.Option key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    )
                }
                <Button loading={loading} type="primary" htmlType="submit">
                    Создать
                </Button>
            </Form>
        </>
    )
}

export default CreateStock