import { gql } from "@apollo/client"

export const SIGN_IN_ADMIN = gql`
	mutation(
		$data: AdminSignInInput!
	){
		signInAdmin(
			data: $data
		){
			admin{
				id
				createdAt
				updatedAt
				delete
				block
				email
				type
				name
				phone
			}
			token
		}
	}
`