import { useMemo } from "react"
import styled from "styled-components"
import {
    Table,
    Popconfirm,
    Button,
    Switch,
    message,
    Form as AntForm,
    Select,
    Input,
    Tag
} from "antd"
import { Link } from "react-router-dom"
import moment from "moment"
import { useQuery } from "@apollo/client"

import { Image, Top } from "../../components"
import { FIND_MANY_NEWS, FIND_MANY_NEWS_COUNT } from "../../gqls"
import { useNavigateSearch, useRouteQuery, parseBoolean } from "../../utils/hooks"

const limit = 20

const Filters = styled(AntForm)`
    margin-bottom: 40px;
    margin-top: 20px;

    .item {
        width: 200px;
    }
`

const NewsList = () => {
    const query = useRouteQuery()
    const { page = 1, search = '', publish = true } = query
    const navigate = useNavigateSearch()

    const variables = useMemo(() => ({
        where: {
            delete: { equals: false },
            publish: typeof parseBoolean(publish) === 'boolean' ? { equals: parseBoolean(publish) } : undefined,
            OR: search ? [
                { title: { contains: search, mode: 'insensitive' } },
                { content: { contains: search, mode: 'insensitive' } },
            ] : undefined
        }
    }), [search, publish])

    const { data, loading } = useQuery(FIND_MANY_NEWS, {
        variables: {
            ...variables,
            take: 10,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
        fetchPolicy: 'network-only'
    })

    const { data: countData, loading: countLoading } = useQuery(FIND_MANY_NEWS_COUNT, {
        variables,
        fetchPolicy: 'network-only'
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/news`, { ...query, page: current })
    }

    const onSubmitSearch = ({ search, publish }) => {
        navigate("/news", { ...query, search, publish })
    }

    const news = useMemo(() => data ? data.findManyNews : [], [data])
    const newsCount = useMemo(() => countData ? countData.findManyNewsCount : 0, [countData])

    return (
        <>
            <Top
                title={"Новости"}
                action={
                    <Link to='add'>
                        <Button>
                            + Добавить
                        </Button>
                    </Link>
                }
            />
            <Filters
                layout='inline'
                onFinish={onSubmitSearch}
                initialValues={{
                    search,
                    publish: parseBoolean(publish)
                }}
            >
                <Filters.Item name={'search'}>
                    <Input
                        placeholder="Поиск"
                        allowClear
                        onChange={e => {
                            if (!e.target.value) {
                                navigate("/news", { ...query, search: '' })
                            }
                        }}
                        className='item'
                    />
                </Filters.Item>
                <Filters.Item name={'publish'}>
                    <Select
                        placeholder="Статус публикации"
                        // onChange={data => setBlock(data)}
                        className='item'
                        allowClear
                        onClear={() => navigate("/news", { ...query, publish: undefined })}
                    >
                        <Select.Option value={false}>
                            Не опубликованный
                        </Select.Option>
                        <Select.Option value={true}>
                            Опубликованный
                        </Select.Option>
                    </Select>
                </Filters.Item>
                <Button
                    htmlType='submit'
                    type='primary'
                >
                    Применить
                </Button>
            </Filters>
            <Table
                loading={loading || countLoading}
                rowKey={(obj) => obj.id}
                dataSource={news}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: page,
                    total: newsCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'Изображение',
                        dataIndex: 'image',
                        key: 'image',
                        render: (image, obj) => (
                            <Link to={obj.id}>
                                <Image
                                    src={`/uploads/${image}`}
                                    style={{
                                        maxWidth: 70,
                                        maxHeight: 70
                                    }}
                                />
                            </Link>
                        ),
                        width: 70
                    },
                    {
                        title: 'Название',
                        dataIndex: 'title',
                        key: 'title',
                        render: (title, obj) => (
                            <Link to={obj.id}>
                                {title}
                            </Link>
                        )
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'publish',
                        key: 'publish',
                        render: (publish) => (
                            <Tag color={publish ? "green" : "red"}>{publish ? "Опубликована" : "Не опубликована"}</Tag>
                        )
                    },
                    {
                        title: 'Дата создание',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt, obj) => (
                            <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                        )
                    },
                ]}
            />
        </>
    )
}

export default NewsList