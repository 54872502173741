import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, Input, Popconfirm, message, TimePicker, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { LoadingView, Top, UploadFile } from '../../components'
import { FIND_UNIQUE_SHOP, UPDATE_ONE_SHOP } from '../../gqls'
import { timeFromDuration } from '../../utils/hooks'
import { SHOP_CATEGORIES } from '../../utils/const'

const Form = styled(AntForm)`
    max-width: 600px;
    .upload-container {
        display: flex;
        border: 1px dashed #d9d9d9;
        position: relative;
        overflow: hidden;
        align-items: center;
        box-sizing: border-box;
        width: fit-content;

        .ant-upload {
            width: 100%;
        }

        .upload {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            height: fit-content;
            font-size: 17px;
            height: 100%;
        }

        .mask {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
            color: #fff;
            font-size: 17px;
            padding: 15px;
            box-sizing: border-box;
        }

        :hover {
            .mask {
                display: flex;
            }
        }
    }
`
const Image = styled.img`
    max-width: 100%;
    aspect-ratio: ${({ ratio }) => ratio};
    object-fit: cover;
    object-position: center;
    background-color: #fafafa;
`
const Note = styled.div`
    margin-bottom: 24px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const EditShop = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const [image, setImage] = useState(undefined)
    const navigate = useNavigate()

    const { data, loading } = useQuery(FIND_UNIQUE_SHOP, {
        variables: {
            where: { id }
        },
        fetchPolicy: 'network-only',
        onCompleted: ({
            findUniqueShop: {
                logo,
                description,
                name,
                startWork,
                endWork,
                address,
                category
            }
        }) => {
            setImage(logo)
            form.setFieldsValue({
                logo,
                name,
                description,
                time: {
                    start: timeFromDuration(startWork),
                    end: timeFromDuration(endWork),
                },
                address,
                category: category ? category : undefined
            })
        }
    })

    const shop = useMemo(() => data ? data.findUniqueShop : null, [data])

    const [updateShop, { loading: updateLoading }] = useMutation(UPDATE_ONE_SHOP, {
        onCompleted: () => {
            form.resetFields()
            message.success("Организация обновлена")
            setImage(undefined)
            navigate('/shop')
        },
        onError: e => {
            message.error("Что то пошло не так, повторите попытку позже")
        }
    })

    const handleSubmit = ({
        time,
        name,
        description,
        logo,
        address,
        category
    }) => {
        const data = {
            startWork: { set: (moment(time.start).hours() * 60) + moment(time.start).minutes() },
            endWork: { set: (moment(time.end).hours() * 60) + moment(time.end).minutes() },
            name: { set: name },
            logo: { set: logo },
            description: { set: description },
            address: { set: address },
            category: { set: category }
        }
        updateShop({
            variables: {
                where: { id },
                data
            }
        })
    }

    return (
        <>
            <Top
                title={'Создание публикации'}
            />
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Form.Item
                    name='logo'
                    rules={[rules.required]}
                    label='Лого'
                >
                    <UploadFile
                        className='upload-container'
                        onChange={name => setImage(name)}
                        itemRender={() => null}
                    >
                        {
                            image ? (
                                <div className='upload'>
                                    <Image
                                        src={`/uploads/${image}`}
                                        ratio={'1/1'}
                                        style={{
                                            borderRadius: '50%',
                                            width: 150,
                                            height: 150
                                        }}
                                    />
                                    <div className='mask'>
                                        <UploadOutlined style={{ marginRight: 5 }} />
                                        Загрузить другое логотип
                                    </div>
                                </div>
                            ) : (
                                <Button icon={<UploadOutlined />}>Загрузить логотип</Button>
                            )
                        }
                    </UploadFile>
                </Form.Item>
                <Form.Item
                    name={"name"}
                    rules={[rules.required]}
                    label="Название организации"
                >
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item
                    name={"description"}
                    rules={[rules.required]}
                    label="Описание"
                >
                    <Input.TextArea
                        placeholder='Введеите описание'
                        style={{
                            minHeight: 200
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={"address"}
                    rules={[rules.required]}
                    label="Адрес магазина"
                >
                    <Input placeholder='Введите адрес' />
                </Form.Item>
                <Form.Item
                    name={"category"}
                    rules={[rules.required]}
                    label="Категория магазина"
                >
                    <Select
                        placeholder='Выберите категорию'
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                    >
                        {
                            SHOP_CATEGORIES.map(item => (
                                <Select.Option key={item}>
                                    {item}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name={"time"}
                    rules={[rules.required]}
                    label="Время работы"
                >
                    <Input.Group
                        compact
                        style={{ marginBottom: 10 }}
                    >
                        <Form.Item
                            name={["time", "start"]}
                            style={{ marginBottom: 0 }}
                            rules={[rules.required]}
                            required={false}
                        >
                            <TimePicker
                                placeholder={'Начало'}
                                showSecond={false}
                                format={"HH:mm"}
                            />
                        </Form.Item>
                        <Form.Item
                            name={["time", "end"]}
                            style={{ marginBottom: 0 }}
                            rules={[rules.required]}
                            required={false}
                        >
                            <TimePicker
                                placeholder={'Конец'}
                                showSecond={false}
                                format={"HH:mm"}
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 15 }}
                >
                    Сохранить
                </Button>
                <Popconfirm
                    title='Удалить магазин?'
                    onConfirm={() => {
                        updateShop({
                            variables: {
                                where: { id },
                                data: {
                                    delete: { set: true }
                                }
                            }
                        })
                    }}
                    disabled={updateLoading}
                >
                    <Button
                        style={{ marginRight: 15 }}
                        type='danger'
                        loading={updateLoading}
                    >
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
            <LoadingView loading={updateLoading} />
        </>
    )
}

export default EditShop