import { useMemo } from 'react'
import styled from 'styled-components'
import {
    Table,
    Popconfirm,
    Button,
    Switch,
    message,
    Form as AntForm,
    Select,
    Input,
    Tag
} from "antd"
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useQuery } from '@apollo/client'

import { Top, Image } from '../../components'
import { useNavigateSearch, useRouteQuery, parseBoolean } from "../../utils/hooks"
import { FIND_MANY_AD, FIND_MANY_AD_COUNT } from '../../gqls'

const limit = 20

const AdList = () => {
    const query = useRouteQuery()
    const { page = 1, search = '', publish = false } = query
    const navigate = useNavigateSearch()

    const variables = useMemo(() => ({
        where: {
            delete: { equals: false },
            publish: { equals: true },
        }
    }), [])

    const { data, loading } = useQuery(FIND_MANY_AD, {
        variables: {
            ...variables,
            take: 10,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
    })
    const { data: countData, loading: countLoading } = useQuery(FIND_MANY_AD_COUNT, {
        variables,
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/ad`, { ...query, page: current })
    }

    const ads = useMemo(() => data ? data.findManyAd : [], [data])
    const adsCount = useMemo(() => countData ? countData.findManyAdCount : [], [countData])

    return (
        <>
            <Top title={"Объявления"} />
            <Table
                loading={loading || countLoading}
                rowKey={(obj) => obj.id}
                dataSource={ads}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: page,
                    total: adsCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'Название',
                        dataIndex: 'title',
                        key: 'title',
                        render: (title, obj) => (
                            <Link to={obj.id}>
                                {title}
                            </Link>
                        )
                    },
                    {
                        title: 'Текст',
                        dataIndex: 'text',
                        key: 'text',
                        render: (text, obj) => (
                            <span
                                className='number-of-lines-2'
                                style={{
                                    maxWidth: 300
                                }}
                            >
                                {text}
                            </span>
                        )
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'publish',
                        key: 'publish',
                        render: (publish) => (
                            <Tag color={publish ? "green" : "red"}>{publish ? "Опубликована" : "Не опубликована"}</Tag>
                        )
                    },
                    {
                        title: 'Дата создание',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt, obj) => (
                            <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                        )
                    },
                ]}
            />
        </>
    )
}

export default AdList