import { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, Input, Select, message, Popconfirm } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { LoadingView, Top, UploadFile } from '../../components'
import { UPDATE_ONE_PHILANTHROPY, FIND_UNIQUE_PHILANTHROPY } from '../../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
    .upload-container {
        display: flex;
        border: 1px dashed #d9d9d9;
        position: relative;
        overflow: hidden;
        align-items: center;
        box-sizing: border-box;
        width: fit-content;

        .ant-upload {
            width: 100%;
        }

        .upload {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            height: fit-content;
            font-size: 17px;
            height: 100%;
        }

        .mask {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
            color: #fff;
            font-size: 17px;
            padding: 15px;
            box-sizing: border-box;
        }

        :hover {
            .mask {
                display: flex;
            }
        }
    }
`
const Image = styled.img`
    max-width: 100%;
    aspect-ratio: ${({ ratio }) => ratio};
    object-fit: cover;
    object-position: center;
    background-color: #fafafa;
`
const Note = styled.div`
    margin-bottom: 24px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const EditPhilanthropy = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const [image, setImage] = useState(undefined)
    const [background, setBackground] = useState(undefined)
    const navigate = useNavigate()

    const { data, loading } = useQuery(FIND_UNIQUE_PHILANTHROPY, {
        variables: {
            where: { id }
        },
        onCompleted: ({
            findUniquePhilanthropy: {
                logo,
                background,
                name,
                description,
                links,
                phone,
                email
            }
        }) => {
            form.setFieldsValue({
                logo,
                background,
                name,
                description,
                links,
                phone,
                email
            })
            setImage(logo)
            setBackground(background)
        },
        onError: e => {
            message.error("Что то пошло не так, повторите попытку позже")
        }
    })

    const [updatePhilanthropy, { loading: updateLoading }] = useMutation(UPDATE_ONE_PHILANTHROPY, {
        onCompleted: () => {
            form.resetFields()
            message.success("Организация обновлена")
            // setImage(undefined)
            // setBackground(undefined)
            navigate('/philanthropy')
        },
        onError: e => {
            message.error("Что то пошло не так, повторите попытку позже")
        }
    })

    const handleSubmit = ({
        name,
        description,
        links,
        phone,
        email
    }) => {
        updatePhilanthropy({
            variables: {
                where: { id },
                data: {
                    logo: { set: image },
                    background: { set: background },
                    name: { set: name },
                    description: { set: description },
                    phone: { set: phone },
                    email: { set: email },
                    links,
                }
            }
        })
    }
    const philanthropy = useMemo(() => data ? data.findUniquePhilanthropy : null, [data])

    return (
        <>
            <Top
                title={'Создание публикации'}
            />
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Form.Item
                    name='logo'
                    rules={[rules.required]}
                    label='Лого'
                >
                    <UploadFile
                        className='upload-container'
                        onChange={name => setImage(name)}
                        itemRender={() => null}
                    >
                        {
                            image ? (
                                <div className='upload'>
                                    <Image
                                        src={`/uploads/${image}`}
                                        ratio={'1/1'}
                                        style={{
                                            borderRadius: '50%',
                                            width: 150,
                                            height: 150
                                        }}
                                    />
                                    <div className='mask'>
                                        <UploadOutlined style={{ marginRight: 5 }} />
                                        Загрузить другое логотип
                                    </div>
                                </div>
                            ) : (
                                <Button icon={<UploadOutlined />}>Загрузить логотип</Button>
                            )
                        }
                    </UploadFile>
                </Form.Item>
                <Form.Item
                    name='background'
                    label='Фоновое изображение'
                >
                    <UploadFile
                        className='upload-container'
                        onChange={name => setBackground(name)}
                        itemRender={() => null}
                    >
                        {
                            background ? (
                                <div className='upload'>
                                    <Image
                                        src={`/uploads/${background}`}
                                        ratio={'16/9'}
                                    />
                                    <div className='mask'>
                                        <UploadOutlined style={{ marginRight: 5 }} />
                                        Загрузить другое изображение
                                    </div>
                                </div>
                            ) : (
                                <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
                            )
                        }
                    </UploadFile>
                </Form.Item>
                <Form.Item
                    name={"name"}
                    rules={[rules.required]}
                    label="Название организации"
                >
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item
                    name={"description"}
                    rules={[rules.required]}
                    label="Описание"
                >
                    <Input.TextArea
                        placeholder='Введеите описание'
                        style={{
                            minHeight: 200
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={"phone"}
                    label="Контактный телефон"
                >
                    <Input placeholder='Введите номер телефона' />
                </Form.Item>
                <Form.Item
                    name={"email"}
                    label="Адрес электронной почты"
                >
                    <Input placeholder='Введите email' />
                </Form.Item>
                <Form.Item
                    name={"links"}
                    label="Ссылки на ресурсы"
                >
                    <Select
                        mode='tags'
                        placeholder='Введите ссылки'
                    />
                </Form.Item>
                <Button
                    loading={updateLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 15 }}
                >
                    Сохранить
                </Button>
                <Popconfirm
                    title='Удлить организацию?'
                    onConfirm={() => {
                        updatePhilanthropy({
                            variables: {
                                where: { id },
                                data: {
                                    delete: { set: true }
                                }
                            }
                        })
                    }}
                >
                    <Button
                        loading={updateLoading}
                        type="danger"
                        style={{ marginRight: 15 }}
                    >
                        Удалить
                    </Button>
                </Popconfirm>
                {
                    philanthropy && (
                        <Button
                            loading={updateLoading}
                            style={{ marginRight: 15 }}
                            onClick={() => {
                                updatePhilanthropy({
                                    variables: {
                                        where: { id },
                                        data: {
                                            publish: { set: !philanthropy.publish }
                                        }
                                    }
                                })
                            }}
                        >
                            {philanthropy.publish ? "Снять с публикации" : "Опубликовать"}
                        </Button>
                    )
                }
            </Form>
            <LoadingView loading={loading} />
        </>
    )
}

export default EditPhilanthropy